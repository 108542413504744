import { useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box, Stack, Group, TextInput, PasswordInput, Button, Text } from '@mantine/core';
import { useForm, FormErrors } from '@mantine/form';
import { useLazySettingsQuery } from '@/entities/admin-app/user/api';
import { useActions } from '@/hooks/redux/action';
import { LoginWraper } from '@/components/login';
import { useAppSelector } from '@/hooks/redux/redux';
import { useFetchToLogin } from './hooks';
import { useFetchToCrypt } from '@/hooks/useFetchToCrypt';
import { isCrypt } from '@/lib/utils/access';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { isSaaS } from '@/lib/utils/access';
import { z } from 'zod';
import { getValidateErrors } from '@/lib/utils/validation';
import { IAuthTypeEnum } from '@/entities/admin-app/auth';
import { ssoRedirectLink, kerberosRedirectLink } from '@/entities/admin-app/constants';
import { useDisclosure } from '@mantine/hooks';
import { preserveCursorPosition } from '@/utils/preserveCursorPosition';

interface FormValues {
  Login: string;
  Password: string;
  CustomerCode?: string;
}

// const show_sso_button = import.meta.env.VITE_SHOW_SSO === 'true';

export const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const [, { data: settingsData }] = useLazySettingsQuery();
  const { setGlobalSettings } = useActions();
  const fetchToLogin = useFetchToLogin();
  const fetchToCrypt = useFetchToCrypt();
  const from = location.state?.from?.pathname || '/users';
  const { classes } = useStyles();
  const passwordInputRef = useRef<HTMLInputElement | null>(null);
  const [visible, { toggle: togglePasswordVisibility }] = useDisclosure(false);

  const validateLoginScheme = z.object({
    Login: z
      .string()
      .min(1, {
        message: t('validation.enterLoginOrEmail')
      })
      .max(255, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 255 }) }),
    Password: z
      .string()
      .min(1, {
        message: t('validation.minLengthSymbolsWithCount.symbols', {
          count: 1
        })
      })
      .max(30, {
        message: t('validation.maxLengthSymbolsWithCount.symbols', {
          count: 30
        })
      })
      .refine((value) => !value.startsWith(' '), {
        message: t('validation.passwordStartsWithSpace')
      }),
    CustomerCode: z
      .string()
      .max(64, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 64 }) })
      .nullable()
      .optional()
  });
  const form = useForm<FormValues>({
    initialValues: {
      Login: '',
      Password: '',
      CustomerCode: undefined
    },
    validateInputOnChange: true,
    validate: (values: FormValues): FormErrors => getValidateErrors(validateLoginScheme, values)
  });

  const getSsoRedirectLink = () => {
    switch (Settings?.AuthType) {
      case IAuthTypeEnum.KERBEROS:
        return kerberosRedirectLink;
      default:
        return ssoRedirectLink;
    }
  };

  const getSsoButtonText = () => {
    switch (Settings?.AuthType) {
      case IAuthTypeEnum.ESIA:
        return t('login.enterESIA');
      case IAuthTypeEnum.GASU:
        return t('login.enterGASU');
      case IAuthTypeEnum.SAML2:
        return t('login.enterSSO');
      case IAuthTypeEnum.KERBEROS:
        return t('login.enterKerberos');
      default:
        return null;
    }
  };

  const handleFetchToCryptSuccess = (encryptedBase64: string, values: FormValues) => {
    const newValues = {
      ...values,
      Password: '',
      CryptedPassword: encryptedBase64
    };

    fetchToLogin({
      values: newValues,
      from
    });
  };

  const handleSubmit = async (values: FormValues) => {
    const trimmedValues = {
      ...values,
      Login: values.Login.trim()
    };

    if (isCrypt(Settings)) {
      await fetchToCrypt(trimmedValues.Password, (encryptedBase64) =>
        handleFetchToCryptSuccess(encryptedBase64, trimmedValues)
      );
    } else {
      await fetchToLogin({ values: trimmedValues, from });
    }
  };

  useEffect(() => {
    if (settingsData) setGlobalSettings(settingsData);
  }, [settingsData]);

  const isValidAuthType =
    Settings?.AuthType &&
    Settings?.AuthType !== '' &&
    Object.values(IAuthTypeEnum).includes(Settings?.AuthType as IAuthTypeEnum);

  const fixCursorLocation = () => {
    preserveCursorPosition(passwordInputRef, togglePasswordVisibility);
  };

  return (
    <LoginWraper title={t('login.enter')}>
      {isSaaS(Settings) && (
        <Box className={classes.linkWrapper}>
          <Text className={classes.subtitle}>Еще нет аккаунта?</Text>
          <Button className={classes.link} component={Link} to="/register" variant="subtle">
            {t('login.register')}
          </Button>
        </Box>
      )}
      <Box component="form" onSubmit={form.onSubmit(handleSubmit)} data-cy="loginForm">
        <Stack gap="lg">
          <Stack gap="xs">
            <Stack gap="xs" className={classes.controls}>
              <TextInput
                size="lg"
                radius="md"
                variant="filled"
                placeholder={isSaaS(Settings) ? t('login.login') : t('login.loginBox')}
                data-cy="usernameInput"
                {...form.getInputProps('Login')}
              />
              <PasswordInput
                size="lg"
                radius="md"
                variant="filled"
                placeholder={t('login.password')}
                data-cy="passwordInput"
                ref={passwordInputRef}
                visible={visible}
                onVisibilityChange={fixCursorLocation}
                {...form.getInputProps('Password')}
              />
              <Group
                justify="space-between"
                className={isSaaS(Settings) ? classes.itemLink : classes.itemsGroup}
              >
                <Box>
                  {/* {!isSaaS(Settings) && (
                    <TextInput
                      size="lg"
                      radius="md"
                      variant="filled"
                      className={classes.responsiveInput}
                      placeholder={t('login.customerCode')}
                      {...form.getInputProps('CustomerCode')}
                      value={form.values.CustomerCode || ''}
                    />
                  )} */}
                </Box>
                <Button
                  component={Link}
                  to="/password-reset"
                  variant="subtle"
                  className={classes.subtleButton}
                  size="md"
                >
                  {t('login.forgotPassword')}
                </Button>
              </Group>
            </Stack>
          </Stack>
          <Group className={classes.footerControls}>
            {!isSaaS(Settings) && isValidAuthType && (
              <Button
                variant="subtle"
                className={classes.responsiveButton}
                size="lg"
                fullWidth={true}
                onClick={() => (window.location.href = getSsoRedirectLink())}
              >
                {getSsoButtonText()}
              </Button>
            )}
            <Button
              className={classes.responsiveButton}
              type="submit"
              size="lg"
              fullWidth={true}
              data-cy="submitButton"
              disabled={Object.keys(form.errors).length !== 0}
            >
              {t('login.enter')}
            </Button>
          </Group>
        </Stack>
      </Box>
    </LoginWraper>
  );
};
