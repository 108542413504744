import { Flex, Loader, Stack } from '@mantine/core';
import { CardContainer } from '../../cardContainer';
import { IconMail } from '@tabler/icons';
import { IListItemProps, ListItem } from '../../listItem';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useGetFilialsInfoCommonQuery } from '@/entities/admin-app/filials/api';
import { useGetEmailServerQuery } from '@/entities/admin-app/email/api';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';

interface IMailSectionProps {
  filialId?: number;
}

export const MailSection: FC<IMailSectionProps> = ({ filialId }) => {
  const { t } = useTranslation();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const { data: filialData, isLoading: linkLoading } = useGetFilialsInfoCommonQuery({
    id: filialId || 0,
    extended: true
  });

  const { data: serversList, isLoading: serversLoading } = useGetEmailServerQuery({
    Id: filialId || 0
  });

  const configuredLink = !!filialData?.Settings?.Smtp?.Email?.Name?.length;
  const configuredServers = !!serversList?.length;

  const safetyMenu: IListItemProps = {
    withCheck: true,
    items: [
      {
        name: t('servers.configMail'),
        link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.common.mailServers}`,
        valid: configuredServers
      },
      ...(!isSaaS(Settings)
        ? [
            {
              name: t('filials.settings.configLink'),
              link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.common.mailingSetup}`,
              valid: configuredLink
            }
          ]
        : [])
    ]
  };

  return (
    <CardContainer sectionName={t('filials.mail')} Icon={IconMail}>
      {linkLoading || serversLoading ? (
        <Flex w="100%" align="center" justify="center">
          <Loader size="sm" />
        </Flex>
      ) : (
        <Stack gap="7px" sx={{ width: '100%' }}>
          <ListItem items={safetyMenu.items} withCheck={safetyMenu.withCheck} />
        </Stack>
      )}
    </CardContainer>
  );
};
