import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LicensesParams, LicenseStatus } from '@/entities/admin-app/licenses/types';
import { RootState } from '@/store';

interface LicensesState {
  activationParams: LicensesParams;
  deactivationParams: LicensesParams;
}

const initialState: LicensesState = {
  activationParams: { offset: 0, count: 10, status: LicenseStatus.Active },
  deactivationParams: { offset: 0, count: 10, status: LicenseStatus.Deactivate }
};

export const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    setLicenseActivationParams: (state, action: PayloadAction<LicensesParams>) => {
      state.activationParams = action.payload;
    },
    setLicenseDeactivationParams: (state, action: PayloadAction<LicensesParams>) => {
      state.deactivationParams = action.payload;
    }
  }
});

export const licensesReducer = licensesSlice.reducer;

export const licensesActions = licensesSlice.actions;

export const selectActivationParams = (state: RootState) => state.licenses.activationParams;

export const selectDeactivationParams = (state: RootState) => state.licenses.deactivationParams;
