import { FC, useEffect, MouseEvent } from 'react';
import { Text } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { IconGripVertical } from '@tabler/icons';
import { useStyles } from './styles';
import { StrictModeDroppable } from '../strict-mode-droppable';
import { IColumnData } from '../simple-table/types';
import { Checkbox } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { pascalToCamel } from '@/utils/pascalToCamel';
import { IndexColumnsType } from '@/entities/admin-app/users';

interface IDndListHandleProps {
  data: IColumnData[];
  setColumnsData?: (columns: IColumnData[]) => void;
  setColumnVisibility?: (data: any) => void;
}

export const TableColumnsDND: FC<IDndListHandleProps> = ({
  data,
  setColumnsData,
  setColumnVisibility
}) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const [state, handlers] = useListState(data);

  const hideColumn = (event: MouseEvent<HTMLButtonElement>) => {
    const value = event.currentTarget.dataset.value;
    if (value !== 'Name') {
      handlers.apply((item) => ({
        ...item,
        hideColumn: value === item.dataIndex ? !item.hideColumn : item.hideColumn
      }));

      if (setColumnsData) {
        setColumnsData(state);
      }
    }
  };

  useEffect(() => {
    if (setColumnsData) {
      setColumnsData(state);
    }

    const updateColumnVisibility = {} as Record<IndexColumnsType, boolean>;
    state.forEach((item) => {
      const hideColumn = item.hideColumn === undefined ? true : item.hideColumn;
      updateColumnVisibility[item.dataIndex as IndexColumnsType] = hideColumn;
    });

    if (setColumnVisibility) {
      setColumnVisibility(updateColumnVisibility);
    }
  }, [state]);

  const allChecked = state.every((item) => !item.hideColumn);

  const toggleAllChecked = () => {
    const allItemsChecked = !allChecked;
    const checkAll = state.map((item) => {
      if (
        item.dataIndex === 'Checkbox' ||
        item.dataIndex === 'Name' ||
        item.dataIndex === 'Action'
      ) {
        return item;
      }
      return {
        ...item,
        hideColumn: !allItemsChecked
      };
    });
    handlers.setState(checkAll);
    if (setColumnsData) {
      setColumnsData(checkAll);
    }
  };

  const items = state.map((item, index) => (
    <Draggable key={item.dataIndex} index={index} draggableId={item.dataIndex || index?.toString()}>
      {(provided, snapshot) => (
        <Checkbox.Card
          onClick={hideColumn}
          data-value={item.dataIndex}
          checked={!item.hideColumn}
          className={cx(classes.item, {
            [classes.itemDragging]: snapshot.isDragging,
            [classes.hideColumn]: !item.title,
            [classes.disabled]: item.dataIndex === 'Name'
          })}
          ref={provided.innerRef}
          data-cy={pascalToCamel(item.dataIndex)}
          {...provided.draggableProps}
        >
          <div className={classes.container}>
            <Checkbox.Indicator disabled={item.dataIndex === 'Name'} />
            <Text fz="sm" pl="sm" style={{ cursor: 'pointer' }}>
              {item.title}
            </Text>
          </div>
          <div {...provided.dragHandleProps} className={classes.dragHandle}>
            <IconGripVertical size={18} stroke={1.5} />
          </div>
        </Checkbox.Card>
      )}
    </Draggable>
  ));

  return (
    <div>
      <DragDropContext
        onDragEnd={({ destination, source }) =>
          handlers.reorder({ from: source.index, to: destination?.index || 0 })
        }
      >
        <Checkbox.Card
          data-cy="selectAll"
          checked={allChecked}
          className={classes.item}
          onClick={toggleAllChecked}
        >
          <div className={classes.container}>
            <Checkbox.Indicator />
            <Text className={classes.label}>{t('users.selectAll')}</Text>
          </div>
        </Checkbox.Card>

        <StrictModeDroppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};
