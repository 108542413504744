import React, { FC } from 'react';
import { Box, Text, Group } from '@mantine/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import OverflowTip from '@components/OverflowTip';

interface ITagList {
  Name: string;
  Id: number;
  Count: number;
}

interface ITextFieldProps {
  title: string;
  tags?: ITagList[];
}

export const TextFieldTags: FC<ITextFieldProps> = ({ title, tags }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.container}>
      <Text className={classes.title}>{title}</Text>
      <Box className={classes.group}>
        <Box className={cx(classes.description)}>
          <Group gap="xs">
            {Array.isArray(tags) && tags.length > 0
              ? tags.map((item, index) => (
                  <Box key={index} maw="300px">
                    <OverflowTip data-text={item.Name} className={classes.rounded}>
                      {item.Name}
                    </OverflowTip>
                  </Box>
                ))
              : t('noInformation')}
          </Group>
        </Box>
      </Box>
    </Box>
  );
};
