import { Layout } from '@/containers/layout';
import { ErrorBoundary } from '@components/error-boundary';
import { useStyles } from './styles';
import { Box, Flex } from '@mantine/core';
import { PaymentSection } from '../home/components/sections/payment';
import { useAppSelector } from '@/hooks/redux/redux';
import { UsersSection } from '../home/components/sections/users';
import { DiskSpaceSection } from '../home/components/sections/diskSpace';
// import { ActivationsSection } from '../home/components/sections/activations';

export const Subscription = () => {
  const { classes } = useStyles();
  const currentUserFilial = useAppSelector((state) => state.userInfo?.User?.Customer.Id);

  return (
    <ErrorBoundary componentTitle="Subscription page">
      <Layout>
        <Box className={classes.subscriptionContainer}>
          <Flex gap="8px" className={classes.flexContainer}>
            <PaymentSection filialId={currentUserFilial} paymentsDate={null} subscriptionPage />
            <UsersSection filialId={currentUserFilial} subscriptionPage />
            <DiskSpaceSection filialId={currentUserFilial} subscriptionPage />
            {/* <ActivationsSection filialId={currentUserFilial} subscriptionPage /> */}
          </Flex>
        </Box>
      </Layout>
    </ErrorBoundary>
  );
};
