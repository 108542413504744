import { FC } from 'react';
import { SidebarMenu } from '@/components/SidebarMenu';
import { getUserEditRoutes } from '@/containers/pages/user/utils';
import { isFeature } from '@utils/feature';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { useGetNavMenuItems } from './useGetNavMenuItems';
import { isSaaS } from '@/lib/utils/access';
import { useAppSelector } from '@/hooks/redux/redux';

interface IMenuProps {
  setIsOpen: (isOpen: boolean) => void;
}

export const NavMenu: FC<IMenuProps> = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const isSaaSProduct = isSaaS(Settings);
  const { editProfileNavItems } = useGetNavMenuItems(isSaaSProduct);

  const linksBlocks = [
    {
      label: t('profil'),
      pages: isFeature()
        ? editProfileNavItems
        : editProfileNavItems.filter((item) => item.value !== ROUTES.profile.pages.settings)
    }
  ];
  return (
    <SidebarMenu routes={getUserEditRoutes()} setIsOpen={setIsOpen} linksBlocks={linksBlocks} />
  );
};
