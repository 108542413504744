import React from 'react';
import OverflowTip from '@/components/OverflowTip';
import { IActionDocsTitles } from '@/entities/admin-app/statistics';

interface IDescriptionProps {
  DescriptionTitle?: string;
  Titles?: IActionDocsTitles[];
}

const createLink = (title: string, url: string, index: number) => (
  <a
    key={`${url}-${index}`}
    href={url}
    target="_blank"
    rel="noreferrer"
    style={{
      maxWidth: '200px'
    }}
  >
    <OverflowTip data-text={title}>{title}</OverflowTip>
  </a>
);

const replaceTitleInPart = (part: string, title: string, url: string) => {
  const splitParts = part.split(title);
  return splitParts.flatMap((splitPart, index) =>
    index < splitParts.length - 1 ? [splitPart, createLink(title, url, index)] : [splitPart]
  );
};

const replaceTitlesWithLinks = ({ DescriptionTitle, Titles }: IDescriptionProps) => {
  let parts: (string | JSX.Element)[] = [DescriptionTitle || ''];

  Titles?.forEach(({ Title, Url }) => {
    parts = parts.flatMap((part) =>
      typeof part === 'string' && Title && Url ? replaceTitleInPart(part, Title, Url) : [part]
    );
  });
  return parts;
};

const useReplaceTitlesWithLinks = ({ DescriptionTitle, Titles }: IDescriptionProps) => {
  return replaceTitlesWithLinks({ DescriptionTitle, Titles });
};

export default useReplaceTitlesWithLinks;
