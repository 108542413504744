import { ActiveFilters } from '@/containers/pages';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { IndexColumnsType } from '@/entities/admin-app/users';

export interface IRowData {
  [key: string]: any;
}

export interface IColumnData {
  width: string;
  dataIndex: IndexColumnsType | string;
  title: keyof IRowData;
  render?: (item: IRowData, selection?: number[]) => ReactNode | JSX.Element | string | number;
  hasSort?: boolean;
  hideColumn?: boolean;
}

export enum IUsersSortField {
  None = 0,
  Name = 1,
  UserType = 2,
  Department = 3,
  Email = 4,
  Phone = 5,
  BirthDate = 6,
  DateOfCreate = 7,
  LastVisitDate = 8,
  Status = 9,
  QuotaName = 10,
  QuotaSize = 11,
  UsingQuotaSize = 12,
  Login = 13,
  Customer = 14,
  LDAP = 20,
  SSO = 21
}

export enum IGroupSortField {
  None = 0,
  Name = 1,
  UserCount = 2,
  ChangeDate = 3,
  DateOfCreate = 4
}

export enum IAuthActionsSortField {
  None = 0,
  ActionType = 1,
  Name = 2,
  Device = 3,
  Os = 4,
  Ip = 5,
  Browser = 6,
  Date = 7
}

export enum IDiskSpaceSortField {
  None = 0,
  Name = 1,
  Space = 4,
  DocsCount = 3,
  FilesCount = 2
}

export enum IUserActivitySortField {
  None = 0,
  Date = 1,
  Visitors = 2,
  Auths = 3,
  Documents = 4,
  Files = 5
}

export interface ISortParams {
  SortOrder: boolean;
  SortField: keyof IRowData;
}

export interface ISimpleTableProps {
  dataCy?: string;
  rowData: IRowData[];
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  columns: IColumnData[];
  search?: string;
  height: string;
  minWidth?: number;
  width: string | number;
  selection?: number[];
  pinnedRows?: number[];
  handleRowClick?: (id: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNextData: () => any;
  allDataLength: number;
  error?: FetchBaseQueryError | SerializedError;
  scrollRef?: any;
  isLoading?: boolean;
  isFetching?: boolean;
  sort?: ISortParams;
  setSort?: (data: ISortParams) => void;
  activeFilters?: ActiveFilters;
  isExpanded?: boolean;
}
