import { IUsersColumnsVisibilityType } from '@/entities/admin-app/users/types';

export const defaultUsersColumnsVisibility: IUsersColumnsVisibilityType = {
  Checkbox: false,
  Name: false,
  Login: true,
  Type: false,
  Email: false,
  Phone: false,
  Customer: false,
  ParentUserName: true,
  BirthDate: true,
  DateOfCreate: true,
  LastVisitDate: true,
  Status: true,
  QuotaName: true,
  QuotaSize: true,
  UsingQuotaSize: true,
  Action: false
} as const;
