import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from '@utils/customBaseQuery';
import { BASE_URL } from '@/entities/admin-app/constants';
import {
  ActivateLicenseBody,
  LicenseItem,
  LicensesParams,
  LicensesResponse
} from '@/entities/admin-app/licenses/types';

export const licensesApi = createApi({
  reducerPath: 'api/licenses',
  baseQuery: customBaseQuery(BASE_URL),
  tagTypes: ['Licenses'],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getLicenses: build.query<LicensesResponse, LicensesParams>({
      query: (params) => ({
        url: 'v1/Licenses',
        params
      }),
      providesTags: ['Licenses']
    }),
    activateLicense: build.mutation<LicenseItem, ActivateLicenseBody>({
      query: (body) => ({
        url: 'v1/Licenses/Activate',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Licenses']
    }),
    deactivateLicense: build.mutation<LicenseItem, number>({
      query: (id) => ({
        url: 'v1/Licenses/Deactivate',
        method: 'PUT',
        params: { id }
      }),
      invalidatesTags: ['Licenses']
    })
  })
});

export const { useGetLicensesQuery, useActivateLicenseMutation, useDeactivateLicenseMutation } =
  licensesApi;
