import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  statusBox: {
    borderRadius: '8px',
    padding: '8px 8px 8px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));
