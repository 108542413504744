import { Container } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import Icon404 from '@/assets/noData/404.svg';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '../error-boundary';
import { Layout } from '@/containers/layout';
import { NoDataContainer } from './components/noDataContainer/noDataContainer';
import { ScrollContainer } from '../scroll-container';

export function NotFound() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const goMainPage = () => {
    navigate('/');
  };

  return (
    <ErrorBoundary componentTitle="404 page">
      <Layout>
        <ScrollContainer sx={{ height: '100%', width: '100%' }} type="auto">
          <Container className={classes.root}>
            <NoDataContainer
              title={t('notFound.somethingWrong')}
              description={t('notFound.404')}
              illustration={Icon404}
              buttons={[
                {
                  buttonText: t('backMain'),
                  handleClick: () => goMainPage()
                }
              ]}
            />
          </Container>
        </ScrollContainer>
      </Layout>
    </ErrorBoundary>
  );
}
