import { FC, useEffect } from 'react';
import { Loader, Select, Tooltip } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useStyles } from './styles';
import userLoadQuotas from './useLoadQuotas';
import { ErrorIcon } from '@/containers/pages/filials/components/select/components/errorIcon';
import { useTranslation } from 'react-i18next';

interface IQuotaSizeProps {
  defaultValue?: number | null;
  form?: UseFormReturnType<any>;
  handleChange?: (query: string | null) => void;
  fieldName: string;
  value?: number | null;
  QuotaName?: string;
  isLimitedAccess?: boolean;
  editUser?: boolean;
  Customer?: number | null;
  createUser?: boolean;
  notClearable?: boolean;
  label?: string;
}

export const SelectQuotas: FC<IQuotaSizeProps> = ({
  defaultValue,
  form,
  handleChange,
  fieldName,
  value,
  QuotaName,
  isLimitedAccess,
  Customer,
  createUser,
  notClearable,
  label
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { list, isFetching, isError, quotaKey, defaultQuotaId } = userLoadQuotas(
    Customer,
    createUser
  );

  const error = form ? form.errors[`${fieldName}.Id`] : '';

  const handleChangeQuota = (query: string | null) => {
    if (form) {
      form?.setFieldValue(fieldName, query ? Number(query) : null);
    } else {
      handleChange && handleChange(query);
    }
  };

  useEffect(() => {
    if (defaultQuotaId) {
      form?.setFieldValue(fieldName, Number(defaultQuotaId));
    }
  }, [defaultQuotaId]);

  return (
    <>
      {isLimitedAccess ? (
        <Select
          data={[{ value: value?.toString() || '', label: QuotaName || '' }]}
          value={`${value || ''}`}
          readOnly={true}
          sx={{ width: '100%' }}
          error={error}
          disabled
        />
      ) : (
        <Select
          comboboxProps={{ withinPortal: true, zIndex: 1400 }}
          className={classes.select}
          key={quotaKey}
          // required
          disabled={isLimitedAccess}
          data={list}
          placeholder={
            list.length ? t('profile.selectDefaultQuota') : t('filials.diskSpace.noQuotas')
          }
          label={label ? label : t('profile.quota')}
          defaultValue={String(defaultValue) ?? ''}
          value={`${value || ''}`}
          sx={{ width: '100%' }}
          onChange={handleChangeQuota}
          error={form?.getInputProps(fieldName).error}
          clearable={notClearable ? false : true}
          withCheckIcon={false}
          rightSection={
            isFetching ? (
              <Loader size="xs" />
            ) : isError ? (
              <Tooltip color="red" label={t('profile.failedToLoadData')}>
                <ErrorIcon />
              </Tooltip>
            ) : undefined
          }
        />
      )}
    </>
  );
};
