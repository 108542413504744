import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQueryTable } from '@/types/api';
import { IUsersInitialFilters } from '@/types/enums/users';
import { IUserTableData, IUsersData, IUsersColumnsVisibilityType } from './types';
import { defaultUsersColumnsVisibility } from '@/entities/admin-app/users/consts';

const getSavedColumnVisibility = (
  localStorageKey: string,
  initialValues: IUsersColumnsVisibilityType
): IUsersColumnsVisibilityType => {
  try {
    const saved = localStorage?.getItem(`${localStorageKey}`);
    return saved ? JSON.parse(saved) : initialValues;
  } catch (error) {
    console.error('Error accessing cookies:', error);
    return initialValues;
  }
};

interface IUsers {
  users: IUserTableData[];
  total: number;
  filters: IQueryTable<IUsersInitialFilters>;
  selectedUserId: number;
  selected: number[];
  columnVisibility: IUsersColumnsVisibilityType;
}

const initialState: IUsers = {
  users: [],
  total: 0,
  filters: {
    Count: 20,
    Offset: 0,
    SortOrder: 0,
    SortField: 0,
    Filters: {},
    Query: '',
    FilterConditions: []
  },
  selectedUserId: 0, // for show UserInfoMain when selectedUserId !== 0
  selected: [],
  columnVisibility: getSavedColumnVisibility('columnVisibility', defaultUsersColumnsVisibility)
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<IUsersData>) {
      state.users = action.payload.Users;
      state.total = action.payload.TotalCount;
    },
    setUsersTableFilters(state, action: PayloadAction<IQueryTable<IUsersInitialFilters>>) {
      state.filters = action.payload;
    },
    setSelectedUserId(state, action: PayloadAction<number>) {
      state.selectedUserId = action.payload;
    },
    setSelectedUsersIds(state, action: PayloadAction<number>) {
      state.selected.includes(action.payload)
        ? (state.selected = state.selected.filter((state) => state !== action.payload))
        : (state.selected = [...state.selected, action.payload]);
    },
    setSelectedAllUsers(state, action: PayloadAction<number[]>) {
      state.selected = state.selected.length === action.payload.length ? [] : action.payload;
    },
    clearUsersSelected(state) {
      state.selected = [];
    },
    setColumnVisibility(state, action: PayloadAction<IUsersColumnsVisibilityType>) {
      try {
        localStorage.setItem('columnVisibility', JSON.stringify(action.payload));
        state.columnVisibility = action.payload;
      } catch (error) {
        console.error('Error saving in cookies:', error);
      }
    }
  }
});

export const usersActions = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
