import React, { FC } from 'react';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';

interface IUserProps {
  UserName?: string;
  UserId?: number;
}

export const User: FC<IUserProps> = ({ UserName, UserId }) => {
  const { classes } = useStyles();

  return (
    <Link className={classes.userItem} target="_blank" to={`/profile/${UserId}`}>
      {UserName}
    </Link>
  );
};
