import { IconLayoutGrid } from '@tabler/icons';
import { CardContainer } from '../../cardContainer';
import calendarIcon from '@assets/icon-calendar.svg';
import drawIcon from '@assets/icon-draw.svg';
import mailIcon from '@/assets/icon-mail.svg';
import contactsIcon from '@assets/icon-contacts.svg';
import projectsIcon from '@assets/icon-projects.svg';
import pagesIcon from '@assets/icon-pages.svg';
import { useTranslation } from 'react-i18next';
import { ModuleItem } from '../../moduleItem';
import { ROUTES } from '@/containers/routes/constants';
import { FC } from 'react';
import { Flex } from '@mantine/core';
import { isSaaS } from '@/lib/utils/access';
import { useAppSelector } from '@/hooks/redux/redux';

export interface IModuleItem {
  label: string;
  icon: string;
  link: string;
}

interface IModulesSectionProps {
  filialId?: number;
}

export const ModulesSection: FC<IModulesSectionProps> = ({ filialId }) => {
  const { t } = useTranslation();
  const { Settings } = useAppSelector((state) => state.userInfo);

  const modulesMenu: IModuleItem[] = [
    {
      label: t('filials.authorization.mail'),
      icon: mailIcon,
      link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.modules.email}`
    },
    {
      label: t('filials.calendar'),

      icon: calendarIcon,
      link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.modules.calendar}`
    },
    {
      label: t('filials.draw'),
      icon: drawIcon,
      link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.modules.draw}`
    },
    {
      label: t('filials.contacts'),
      icon: contactsIcon,
      link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.modules.contacts}`
    },
    {
      label: t('filials.projects'),
      icon: projectsIcon,
      link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.modules.projects}`
    },
    ...(isSaaS(Settings)
      ? [
          {
            label: t('filials.pages'),
            icon: pagesIcon,
            link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.modules.pages}`
          }
        ]
      : [])
  ];

  return (
    <CardContainer sectionName={t('filials.modules.config')} Icon={IconLayoutGrid}>
      <Flex wrap="wrap">
        {modulesMenu.map((item: any, index: number) => (
          <ModuleItem item={item} key={index} />
        ))}
      </Flex>
    </CardContainer>
  );
};
