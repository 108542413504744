import { FC, MouseEvent } from 'react';
import { Box, Radio, Text } from '@mantine/core';
import { useStyles } from './styles';

interface IGroupItemProps {
  checked?: boolean;
  defaultChecked?: boolean;
  label: string;
  handleChange?: (event: MouseEvent<HTMLButtonElement>) => void;
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  value: number;
  counter?: number;
  withRadioButtonbox?: boolean;
  selectedIdGroup?: number | null;
}

export const GroupItem: FC<IGroupItemProps> = ({
  defaultChecked,
  checked,
  label,
  value,
  counter,
  handleChange,
  handleClick,
  withRadioButtonbox = false,
  selectedIdGroup
}) => {
  const { classes, cx } = useStyles();

  const handleItemClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (withRadioButtonbox && handleChange) {
      handleChange(e);
    }
    if (handleClick) {
      handleClick(e);
    }
  };

  return (
    <Radio.Card
      onClick={handleItemClick}
      data-value={value}
      defaultChecked={defaultChecked ?? undefined}
      checked={Boolean(checked)}
      className={cx(classes.root, {
        [classes.selectedGroup]: Boolean(selectedIdGroup) && value === selectedIdGroup
      })}
    >
      {withRadioButtonbox ? (
        <>
          <Radio.Indicator />
          <Box className={classes.checkboxContainer}>
            <Text className={classes.checkbox} pl="sm">
              {label}
            </Text>
            <Text className={classes.counter}>{counter && counter}</Text>
          </Box>
        </>
      ) : (
        <Box className={classes.checkboxContainer} data-value={value}>
          <Text className={classes.checkbox}>{label}</Text>
          <Text className={classes.counter}>{counter && counter}</Text>
        </Box>
      )}
    </Radio.Card>
  );
};
