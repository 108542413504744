import { Container } from '@mantine/core';
import Icon500 from '@/assets/noData/500.svg';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { NoDataContainer } from '@/components/not-found/components/noDataContainer/noDataContainer';

export const ErrorBoundaryPage = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <NoDataContainer
        title={t('notFound.somethingWrong')}
        description={t('notFound.tryAgain')}
        illustration={Icon500}
        buttons={[
          {
            buttonText: t('notFound.reload'),
            handleClick: () => window.location.reload()
          }
        ]}
      />
    </Container>
  );
};
export default ErrorBoundaryPage;
