import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  item: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    paddingLeft: `calc(${theme.spacing.xl} - ${theme.spacing.md})`,
    [utils.dark]: {
      backgroundColor: theme.colors.dark[5]
    },
    [utils.light]: {
      backgroundColor: theme.white
    },
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.backgroundGray[6]
    },

    '& .mantine-CheckboxIndicator-indicator': {
      cursor: 'pointer'
    }
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
    backgroundColor: theme.colors.backgroundGray[6]
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },

  dragHandle: {
    // ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    [utils.dark]: {
      color: theme.colors.dark[1]
    },
    [utils.light]: {
      color: theme.colors.gray[6]
    },
    '&:focus': {
      outline: '0.125rem solid #339af0',
      outlineOffset: '0.125rem'
    },
    '&:focus:not(:focus-visible)': {
      outline: 'none'
    },
    WebkitTapHighlightColor: 'transparent'
  },

  hideColumn: {
    display: 'none'
  },

  toggleAll: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`
  },

  label: {
    cursor: 'pointer',
    marginLeft: theme.spacing.sm
  },

  disabled: {
    opacity: 0.5
  }
}));
