import { useContext, useEffect, useRef, useState } from 'react';
import { ActionIcon, Badge, Box, Divider, Flex, Loader, Text } from '@mantine/core';
import { useStyles } from './styles';
import { PlusIcon, UsersIcon } from '@/assets/icons';
import { RolesInfoMainContext } from '../../panels';
import { useLazyGetUsersQuery } from '@/entities/admin-app/users/api';
import { IUserTableData } from '@/entities/admin-app/users';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from '@/hooks/useDebounce';
import { themeColors } from '@/theme/colors';
import { UserItem } from './user-item';
import { Search } from '@/components/search/search-input';
import { SelectUserModal } from '@/components/modal/select-user';
import { IFiltersEnumType } from '@/types/enums';
import { useAddRoles } from '@/hooks/users/useAddRoles';
import { useDeleteRole } from '@/hooks/users/useDeleteRoles';
import { RoleTypesEnum } from '@/types/enums/user';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/redux/redux';
import { isSuperAdmin } from '@/lib/utils/access';
import { useWindowSize } from '@/hooks/useWindowSize';

export const UserRoles = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const scrollRef = useRef<any>(null);
  const divRef = useRef<any>(null);
  const windowSize = useWindowSize();
  const { rolesInfoCommon } = useContext(RolesInfoMainContext);
  const User = useAppSelector((state) => state?.userInfo?.User);

  const [search, setSearch] = useState<string>('');
  const [usersList, setUsersList] = useState<IUserTableData[]>([]);

  const [offset, setOffset] = useState(0);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const { openDeleteRole } = useDeleteRole(setUsersList);
  const { handleAddRoles } = useAddRoles(rolesInfoCommon?.Id ? [rolesInfoCommon.Id] : []);

  const debounced = useDebounce(search, 500);

  const [fetchGetUsers, { data, isLoading, isFetching }] = useLazyGetUsersQuery();

  const [containerHeight, setContainerHeight] = useState('auto');

  useEffect(() => {
    if (divRef.current) {
      const scrollRect = divRef.current.offsetTop;
      const availableHeight = windowSize.height - scrollRect - 20;
      const maxUserItems = 5;
      const userItemHeight = 70;

      if (usersList.length <= maxUserItems) {
        setContainerHeight('auto');
      } else {
        const totalHeightOfItems = usersList.length * userItemHeight;

        if (totalHeightOfItems <= availableHeight) {
          setContainerHeight('auto');
        } else if (availableHeight > 380) {
          setContainerHeight(`${availableHeight}px`);
        } else {
          setContainerHeight('380px');
        }
      }
    }
  }, [windowSize, usersList.length]);

  const showDeleteItemIcon = (userId: number): boolean => {
    if (isSuperAdmin(User)) {
      return userId !== User?.Id && rolesInfoCommon?.Type !== RoleTypesEnum.All;
    } else {
      return (
        userId !== User?.Id &&
        rolesInfoCommon?.Type !== RoleTypesEnum.All &&
        rolesInfoCommon?.Type !== RoleTypesEnum.SuperUser
      );
    }
  };

  const showPlusIcon = (): boolean => {
    if (data) {
      if (isSuperAdmin(User)) {
        return rolesInfoCommon?.Type !== RoleTypesEnum.All;
      } else {
        return (
          rolesInfoCommon?.Type !== RoleTypesEnum.All &&
          rolesInfoCommon?.Type !== RoleTypesEnum.SuperUser
        );
      }
    } else return false;
  };

  const showAddUsersBadge = showPlusIcon() && !data?.Users.length && !search && !isLoading;

  useEffect(() => {
    if (rolesInfoCommon) {
      fetchGetUsers({
        Count: 20,
        Offset: offset,
        SortOrder: 0,
        SortField: 0,
        Filters: {},
        Query: debounced,
        FilterConditions: [
          {
            Type: IFiltersEnumType.RoleFilter,
            NumValues: [rolesInfoCommon?.Id]
          }
        ]
      });
    }
  }, [rolesInfoCommon, debounced, offset]);

  useEffect(() => {
    if (data) {
      const usersData = offset > 0 ? [...usersList, ...data.Users] : data.Users;
      setUsersList(usersData);
      if (!search) {
        setTotalCount(data?.TotalCount);
      }
    }
  }, [data]);

  useEffect(() => {
    if (search) {
      scrollRef?.current?.el?.scrollTo(0, 0);
    }
    setOffset(0);
  }, [search]);

  const handleLoadMore = () => {
    setOffset((prevState) => prevState + 20);
  };

  const closeModal = () => {
    setIsUserModalOpen(false);
  };

  const addRoles = () => {
    if (rolesInfoCommon) {
      const rolesIds = [rolesInfoCommon?.Id];
      handleAddRoles(selectedUsers, rolesIds);
      closeModal();
    }
  };

  const handleDelete = (userId: number) => {
    if (rolesInfoCommon?.Id) {
      openDeleteRole(userId, rolesInfoCommon?.Id);
    }
  };

  const removeInfiniteScrollOverflow = usersList?.length < 5 ? { overflow: 'none' } : {};

  return (
    <>
      <Divider className={classes.divider} />
      <Box className={classes.header}>
        <Flex gap="xs" align="center">
          <UsersIcon stroke={themeColors.brandGray[0]} />
          <Text className={classes.title}>
            {t('filials.diskSpace.table.users')}: {data?.TotalCount}
          </Text>
        </Flex>
        {showPlusIcon() && (
          <ActionIcon size="md" onClick={() => setIsUserModalOpen(true)}>
            <PlusIcon />
          </ActionIcon>
        )}
      </Box>
      {totalCount !== 0 ? (
        <Search
          setSearch={setSearch}
          className={classes.search}
          fullSize
          value={search}
          placeholder={t('search')}
        />
      ) : null}
      <div ref={divRef}>
        <InfiniteScroll
          ref={scrollRef}
          dataLength={usersList?.length}
          next={handleLoadMore}
          hasMore={
            data && data.TotalCount !== undefined ? data.TotalCount > usersList?.length : false
          }
          loader={
            <>
              {Boolean(usersList?.length) && (
                <Box className={classes.infiniteScrollMoreData}>
                  <Loader size="sm" />
                </Box>
              )}
            </>
          }
          className={classes.infiniteScroll}
          height={containerHeight}
          style={{ ...removeInfiniteScrollOverflow }}
        >
          {(isLoading || isFetching) && (
            <Box className={classes.loader}>
              <Loader size="sm" />
            </Box>
          )}
          {usersList?.length > 0 &&
            !isLoading &&
            usersList?.map((item) => {
              return (
                <UserItem
                  key={item.Id}
                  item={item}
                  userId={item.Id}
                  handleClick={() => handleDelete(item.Id)}
                  roleType={rolesInfoCommon?.Type}
                  showDeleteItemIcon={showDeleteItemIcon(item.Id)}
                  showUserHover={showPlusIcon}
                />
              );
            })}
          {showAddUsersBadge && (
            <Badge
              className={classes.badge}
              variant="outline"
              color="gray"
              onClick={() => setIsUserModalOpen(true)}
              rightSection={
                <ActionIcon size={16} variant="transparent">
                  <PlusIcon />
                </ActionIcon>
              }
            >
              {t('roles.addUsers')}
            </Badge>
          )}
          {!usersList.length && !isLoading && !isFetching && search && (
            <Text className={classes.emptyListText}>{t('userNotFound')}</Text>
          )}
        </InfiniteScroll>
      </div>
      <SelectUserModal
        multipleUsers={true}
        isOpened={isUserModalOpen}
        title={t('filials.modules.addUser')}
        onClose={closeModal}
        userId={selectedUsers[0]}
        setSelectedUsers={setSelectedUsers}
        usersList={usersList}
        controlSection
        controlSectionProps={{
          confirmProps: {
            btnName: `${t('add')} ${selectedUsers.length > 0 ? selectedUsers.length : ''}`,
            fn: addRoles
          },
          cancelProps: {
            btnName: t('cancel'),
            fn: closeModal
          }
        }}
      />
    </>
  );
};
