import React, { MouseEvent, FC } from 'react';
import { Box, Checkbox, Text } from '@mantine/core';
import { useStyles } from './styles';

interface IListItemProps {
  checked?: boolean;
  defaultChecked?: boolean;
  label: string;
  handleChange?: (event: MouseEvent<HTMLButtonElement>) => void;
  value: number;
  counter?: number;
}

export const ListItem: FC<IListItemProps> = ({
  defaultChecked,
  checked,
  label,
  value,
  // counter,
  handleChange
}) => {
  const { classes } = useStyles();

  return (
    <Checkbox.Card
      className={classes.link}
      data-link={value}
      checked={!!checked}
      defaultChecked={defaultChecked}
      onClick={handleChange}
    >
      <Checkbox.Indicator />
      <Box className={classes.checkboxContainer}>
        <Text fz="sm" className={classes.checkbox}>
          {label}
        </Text>
        {/* Counter does not work properly when multiple filters  */}
        {/* <Text className={classes.counter}>{count}er</Text> */}
      </Box>
    </Checkbox.Card>
  );
};
