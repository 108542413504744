import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  userItem: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: theme.colors.mainBlue[8],
      textDecoration: 'underline'
    }
  }
}));
