import { MutableRefObject, useEffect } from 'react';
import { IRowData } from '@components/simple-table/types';
import { useActions } from '@hooks/redux/action';
import { IStatisticsInitialFilters } from '@/types/enums/statistics';

interface IUseHandleUsersActions {
  scrollRef: MutableRefObject<any>;
  search: string;
  rowData?: IRowData[];
  usersActionsFilters: IStatisticsInitialFilters;
}

export const useHandleUsersActions = ({
  scrollRef,
  search,
  rowData,
  usersActionsFilters
}: IUseHandleUsersActions) => {
  const { setUsersActionsFilters } = useActions();

  useEffect(() => {
    if (search) {
      scrollRef?.current?.el?.scrollTo(0, 0);
    }

    setUsersActionsFilters({
      ...usersActionsFilters,
      Query: search,
      Offset: 0
    });
  }, [search]);

  useEffect(() => {
    if (rowData?.length) {
      setUsersActionsFilters({
        ...usersActionsFilters
      });
    }
  }, [rowData]);

  useEffect(() => {
    scrollRef?.current?.el?.scrollTo(0, 0);
  }, [usersActionsFilters]);
};
