import { useState, useEffect } from 'react';

import { useGetDefaultUsersActionsFiltersQuery } from '@/entities/admin-app/statistics/api';
import { IFilterMenu } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { filtersLabelsKeys } from '@/entities/admin-app/constants';
import { useTranslation } from 'react-i18next';

export const useGetUsersActionsFilters = () => {
  const { t } = useTranslation();
  const { data: defaultFiltersData, isSuccess: defaultFiltersSuccess } =
    useGetDefaultUsersActionsFiltersQuery(20);

  const [filtersMenuList, setFiltersMenuList] = useState<IFilterMenu[]>([]);

  useEffect(() => {
    if (!(defaultFiltersData && defaultFiltersSuccess)) {
      return;
    }
    const convertDefaultFilters: IFilterMenu[] = defaultFiltersData
      .map((item) => ({
        ...item,
        label:
          item.Type === IFiltersEnumType.DateOfCreateFilter
            ? t('statistics.date_action')
            : item.Type in IFiltersEnumType
            ? t(`filters.${filtersLabelsKeys[item.Type]}`)
            : t('filters.title'),
        Items: item.Items?.map((filterItem) => ({
          ...filterItem,
          Count: undefined,
          checked: false
        })),
        hideSearch: false
      }))
      .sort((a, b) => a.Type - b.Type);
    setFiltersMenuList(convertDefaultFilters);
  }, [defaultFiltersData, defaultFiltersSuccess]);

  return { filtersMenuList };
};
