import { createStyles } from '@mantine/emotion';
import { HEADER_HEIGHT } from '@components/header/constants';

export const useStyles = createStyles(() => ({
  root: {
    minHeight: `calc(100dvh - ${HEADER_HEIGHT}px)`,
    padding: '40px 0',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    wordBreak: 'keep-all',
    maxWidth: '510px'
  }
}));
