import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((_, __, utils) => ({
  container: {
    backgroundColor: 'white',
    padding: '20px 24px 24px 24px',
    borderRadius: '16px',
    border: '1px solid #F1F3F5'
  },

  fixedWidth: {
    width: '340px',
    [utils.smallerThan('xs')]: {
      width: '100%'
    }
  },

  adaptiveWidth: {
    [utils.largerThan('xl')]: {
      width: '340px'
    },
    [utils.smallerThan('xl')]: {
      width: '24.5%'
    },
    [utils.smallerThan('lg')]: {
      width: '33%'
    },
    [utils.smallerThan('md')]: {
      width: '50%'
    },
    [utils.smallerThan('xs')]: {
      width: '99%'
    }
  },

  badgeRoot: {
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    borderRadius: '16px',
    padding: `0px 6px`,
    backgroundColor: '#D0EBFF'
  },

  badgeInner: {
    color: '#228BE6',
    fontSize: '12px',
    fontWeight: 500
  }
}));
