import { createStyles } from '@mantine/emotion';
import { HEADER_HEIGHT } from '@components/header/constants';

export const useStyles = createStyles((theme, _, utils) => ({
  container: {
    height: '100%',
    width: '100%',
    padding: 24,
    backgroundColor: theme.colors.backgroundGray[0],
    [utils.largerThan('md')]: {
      height: `calc(100vh - ${HEADER_HEIGHT}px)`
    }
  }
}));
