export enum LicenseStatus {
  Active = 1,
  Deactivate = 2
}

export interface LicenseItem {
  CustomerId: number;
  InstallationGuid: string;
  MachineHostname: string;
  MachineId: string;
  MachineIp: string;
  OperatingSystem: string;
  ProductType: string;
  ProductVersion: string;
  FileName: string;
  Data: string;
  GroupId: string;
  Id: number;
  SubscriptionId: number;
  Status: LicenseStatus;
  StartDate: string;
  EndDate: string;
  Timestamp: string;
}

export interface LicensesParams {
  offset: number;
  count: number;
  status: LicenseStatus;
  query?: string;
}

export type ActivateLicenseBody = Pick<
  LicenseItem,
  | 'InstallationGuid'
  | 'MachineHostname'
  | 'MachineId'
  | 'MachineIp'
  | 'OperatingSystem'
  | 'ProductType'
  | 'ProductVersion'
  | 'GroupId'
  | 'SubscriptionId'
>;

export interface LicensesResponse {
  Items: LicenseItem[];
  TotalCount: number;
}
