import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  listGroup: {
    width: '100%',
    display: 'flex',
    columnGap: '4px',
    flexWrap: 'wrap',
    backgroundColor: 'transparent'
  }
}));
