import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  root: {
    paddingTop: '24px',
    paddingBottom: '24px',
    minHeight: '100dvh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    wordBreak: 'keep-all',
    maxWidth: '410px'
  }
}));
