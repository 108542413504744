import { ErrorBoundary } from '@components/error-boundary';
import { Layout } from '@/containers/layout';
import { Licenses } from '@components/licenses';
import { Stack } from '@mantine/core';

import { useStyles } from './styles';

export const Activations = () => {
  const { classes } = useStyles();

  return (
    <ErrorBoundary componentTitle="Activations page">
      <Layout>
        <Stack className={classes.container}>
          <Licenses />
        </Stack>
      </Layout>
    </ErrorBoundary>
  );
};
