import { IStatisticsInitialFilters } from '@/types/enums/statistics';
import { IAuditColumnsVisibilityType } from '@/containers/pages/statistics/types';

export const listSizes = [25, 50, 100] as const;

export const initialStatisticFilter: IStatisticsInitialFilters = {
  Offset: 0,
  SortOrder: 1,
  SortField: 0,
  FilterConditions: [],
  Query: '',
  Count: listSizes[0]
};

export const defaultAuditColumnsVisibility: IAuditColumnsVisibilityType = {
  UserName: false,
  ActionType: false,
  Date: false,
  Description: false,
  Ip: true,
  NewValue: true,
  OldValue: true
} as const;
